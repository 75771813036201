@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --blue-dark: #19202b;
    --blue: #293547;
    --blue-light: #425572;
    --blue-lightest: #8296b6;

    --azure: #5352f6;
    --azure-pastel: #8495ff;
    --azure-light: #c6cbf8;
    --azure-lightest: #e2e5fb;

    --gray-darkest: #393c40;
    --gray-dark: #6c7481;
    --gray: #a2aab7;
    --gray-light: #dadfe6;
    --gray-lightest: #eff2f6;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.absolute-center-v {
    @apply top-1/2 -translate-y-1/2;
}

.absolute-center-h {
    @apply right-1/2 translate-x-1/2;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: hsl(var(--twc-blue)) hsl(var(--twc-blue-dark));
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: hsl(var(--twc-blue-dark));
}

*::-webkit-scrollbar-thumb {
    background-color: hsl(var(--twc-blue));
    border-radius: 14px;
    border: 3px solid hsl(var(--twc-blue-dark));
}

*::-webkit-scrollbar-corner {
    background: hsl(var(--twc-blue-dark));
    border: solid hsl(var(--twc-blue));
    border-width: 1px 0px 0px 1px;
}

a {
    color: hsl(var(--twc-azure-pastel));
}

.h-smallest-screen {
    height: 100svh !important;
}
